import Hls from "hls.js"

const hls = new Hls()
const video = document.getElementById("video")
const stream = video.dataset.src

const fullscreen = function () {
  let isInFullScreen =
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement &&
      document.webkitFullscreenElement !== null) ||
    (document.webkitDisplayingFullscreen &&
      document.webkitDisplayingFullscreen !== null) ||
    (document.mozFullScreenElement &&
      document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null);

  if (!isInFullScreen) {
    if (video.requestFullscreen) {
      video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
      video.mozRequestFullScreen();
    } else if (video.webkitRequestFullScreen) {
      video.webkitRequestFullScreen();
    } else if (video.webkitEnterFullscreen) {
      video.webkitEnterFullscreen();
    } else if (video.msRequestFullscreen) {
      video.msRequestFullscreen();
    }
    video.play();
  } else {
    if (video.exitFullscreen) {
      video.exitFullscreen();
    } else if (video.webkitExitFullscreen) {
      video.webkitExitFullscreen();
    } else if (video.mozCancelFullScreen) {
      video.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      video.msExitFullscreen();
    }
  }
}

if (Hls.isSupported()) {
  hls.subtitleDisplay = false // disable subtitle
  hls.loadSource(stream)
  hls.attachMedia(video)
} else if (video.canPlayType("application/vnd.apple.mpegurl")) {
  video.src = stream;
}

document.getElementById("fullscreen").addEventListener('click', fullscreen)

// unregister service workers
// https://stackoverflow.com/questions/33704791/how-do-i-uninstall-a-service-worker
if (window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}
